<script setup lang="ts">
import { ref, computed } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxLabel,
  TransitionRoot,
} from "@headlessui/vue";
import { useField } from "vee-validate";
import type { SelectItem } from "@/types";

interface Emit {
  (event: "update:modelValue", value: SelectItem | SelectItem[] | null | undefined): void;
}

interface Props {
  items: SelectItem[];
  modelValue: SelectItem | SelectItem[] | null | undefined;
  name: string;
  validateAs?: string;
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  multiple?: boolean;
  allowEmpty?: boolean;
  virtual?: boolean;
  optionsWidth?: string;
}
const props = withDefaults(defineProps<Props>(), {
  optionsWidth: "w-full",
});

const emit = defineEmits<Emit>();

const query = ref("");
const combobox = ref();

const { errorMessage, resetField } = useField(() => props.name, props.validateAs, {
  syncVModel: true,
});

const filteredItems = computed(() =>
  query.value === ""
    ? props.items
    : props.items.filter((item) =>
        (item.name as string)
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.value.toLowerCase().replace(/\s+/g, ""))
      )
);

const virtual = computed(() => {
  return props.virtual
    ? filteredItems.value.length > 0
      ? { options: filteredItems.value }
      : { options: [{ disabled: true, empty: true }] }
    : null;
});

const selectValue = computed<SelectItem | SelectItem[] | null | undefined>({
  get: () => props.modelValue ?? ((props.multiple ? [] : null) as SelectItem | SelectItem[] | null | undefined),
  set: (value: SelectItem | SelectItem[] | null | undefined) => {
    if (value !== selectValue.value) emit("update:modelValue", value);
  },
});

const removeOption = (index?: number) => {
  if (index !== undefined && typeof index === "number" && Array.isArray(selectValue.value) && !props.disabled) {
    const newArr = selectValue.value.filter((_, i) => i !== index);
    emit("update:modelValue", newArr);
  } else if (selectValue.value && !props.disabled) {
    emit("update:modelValue", null);
  }
};

// defineExpose({ reset: () => resetField() });
defineExpose({ reset: () => emit("update:modelValue", null) });
</script>

<template>
  <div v-auto-animate>
    <Combobox
      ref="combobox"
      v-model="selectValue"
      v-slot="{ open }"
      as="div"
      by="id"
      :multiple="multiple"
      :disabled="disabled"
      class="group"
      :class="{ 'opacity-50': disabled }"
      nullable
      :virtual="virtual"
    >
      <ComboboxLabel
        v-if="label"
        class="px-1 text-xs group-focus-within:text-primary"
        :class="{ 'text-primary': open }"
      >
        {{ label }}
      </ComboboxLabel>

      <TransitionGroup
        v-if="multiple && Array.isArray(selectValue) && selectValue.length"
        name="list"
        tag="div"
        class="flex flex-wrap gap-2 px-1 mt-1 mb-2"
      >
        <div
          v-for="(value, index) in selectValue"
          :key="value.id"
          class="text-xs flex gap-1 items-center py-1 px-2 text-white rounded-md"
          :class="disabled ? 'bg-medium-gray' : 'bg-primary'"
        >
          {{ value.name }}
          <Icon
            name="fa6-solid:xmark"
            class="mt-px w-3 h-3"
            :class="{ ' cursor-pointer transition-all hover:rotate-180': !disabled }"
            @click="removeOption(index)"
          />
        </div>
      </TransitionGroup>

      <div class="relative">
        <div>
          <ComboboxButton class="w-full">
            <ComboboxInput
              class="focus:border-primary min-h-[2.4rem] border text-sm pr-10 rounded-md w-full focus:ring-0 focus:shadow-md"
              :class="[errorMessage ? 'border-danger' : 'border-light-gray']"
              :placeholder="placeholder"
              :display-value="(item) => (!multiple ? (item as SelectItem)?.name : '')"
              @change="query = $event.target.value"
            />
            <div class="absolute right-0 inset-y-0 flex items-center pr-2">
              <Icon
                v-if="multiple || !allowEmpty"
                name="fa6-solid:chevron-down"
                :class="{ 'rotate-180': open }"
                class="h-3 w-3 text-medium-gray transition-transform"
                aria-hidden="true"
              />
              <Icon
                v-else-if="allowEmpty && !selectValue"
                name="fa6-solid:chevron-down"
                :class="{ 'rotate-180': open }"
                class="h-3 w-3 text-medium-gray transition-transform"
                aria-hidden="true"
              />
            </div>
          </ComboboxButton>
          <div
            v-if="allowEmpty && !multiple && selectValue && !open"
            class="absolute cursor-pointer right-0 inset-y-0 flex items-center pr-2"
          >
            <Icon
              @click="removeOption"
              name="fa6-solid:xmark"
              :class="{ 'rotate-180': open }"
              class="h-4 w-4 text-medium-gray transition-transform"
              aria-hidden="true"
            />
          </div>
        </div>
        <TransitionRoot
          leave="transition ease-in duration-100"
          leave-from="opacity-100"
          leave-to="opacity-0"
          @after-leave="query = ''"
        >
          <ComboboxOptions
            v-if="!virtual"
            class="absolute bg-white max-h-60 mt-1 overflow-auto py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-base sm:text-sm z-10 focus:outline-none"
            :class="optionsWidth"
          >
            <div
              v-if="filteredItems.length === 0 && query !== ''"
              class="cursor-default px-4 py-2 relative select-none"
            >
              <div v-if="$slots.noResults">
                <slot name="noResults" />
              </div>
              <p v-else>Nothing found.</p>
            </div>

            <ComboboxOption
              v-for="item in filteredItems"
              :key="item.name"
              :value="item"
              v-slot="{ active, selected }"
            >
              <li
                class="cursor-default mx-1 pl-4 sm:pl-10 pr-4 py-2 relative select-none rounded-md"
                :class="[{ 'bg-primary text-white': active }, { 'flex justify-between items-center': item.count }]"
              >
                <span
                  class="block font-normal truncate ui-selected:font-medium"
                  :class="{ 'font-medium': selected }"
                >
                  <div v-if="$slots.listItem">
                    <slot name="listItem" :item="item" :active="active" :selected="selected" />
                  </div>
                  <div v-else>
                    {{ item?.name }}
                  </div>
                </span>
                <span class="text-xs pl-5" v-if="item.count">{{ item.count }}</span>
                <span
                  class="hidden absolute left-0 inset-y-0 items-center pl-3 text-primary"
                  :class="[selected ? 'sm:flex' : 'hidden', { 'text-white': active }]"
                >
                  <Icon name="fa6-solid:check" class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
          <ComboboxOptions
            v-if="virtual"
            class="absolute bg-white max-h-60 mt-1 overflow-auto py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-base sm:text-sm z-10 focus:outline-none"
            :class="optionsWidth"
            v-slot="{ option: virtualItem }"
          >
            <ComboboxOption v-if="virtualItem?.empty" disabled class="w-full">
              <div class="cursor-default px-4 py-2 relative select-none">
                <div v-if="$slots.noResults">
                  <slot name="noResults" />
                </div>
                <p v-else>Nothing found.</p>
              </div>
            </ComboboxOption>
            <ComboboxOption v-else :value="virtualItem" v-slot="{ active, selected }" class="w-full">
              <li
                class="cursor-default mx-1 pl-4 sm:pl-10 pr-4 py-2 relative select-none rounded-md"
                :class="[
                  { 'bg-primary text-white': active },
                  { 'flex justify-between items-center': virtualItem?.count },
                ]"
              >
                <span
                  class="block font-normal truncate ui-selected:font-medium"
                  :class="{ 'font-medium': selected }"
                >
                  <div v-if="$slots.listItem">
                    <slot name="listItem" :item="virtualItem" :active="active" :selected="selected" />
                  </div>
                  <div v-else>
                    {{ virtualItem?.name }}
                  </div>
                </span>
                <span v-if="virtualItem?.count">{{ virtualItem.count }}</span>
                <span
                  class="hidden absolute left-0 inset-y-0 items-center pl-3 text-primary"
                  :class="[selected ? 'sm:flex' : 'hidden', { 'text-white': active }]"
                >
                  <Icon name="fa6-solid:check" class="h-5 w-5" aria-hidden="true" />
                </span>
              </li>
            </ComboboxOption>
          </ComboboxOptions>
        </TransitionRoot>
      </div>
    </Combobox>
    <InputError v-if="errorMessage">{{ errorMessage }}</InputError>
  </div>
</template>
